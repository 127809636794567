
.titulo-nosotros{
    background-color: var(--fuerte);
    
}
.description-nosotros{
    font-size: 0.8rem;
}
.linea_azul{
    background-color: var(--fuerte);
    height: 2rem;
    
}

.avionmovil{

    margin-left: 26px;
    margin-top: -42px;
}

.flechamision{
    
        margin-left: 17px;
    
}