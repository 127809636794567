:root{
 --cielo: #b1c9eb;
 --medio: #4c6fbf;
--fuerte:#004aad;
}

body {
  margin: 0;
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.texto-m{
  font-size: 1rem;
}


.text-shadow-drop-center {
	text-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
}

body a{
  text-decoration: none;
  color: inherit !important;
}

body ul{
  list-style: none;
}

body::-webkit-scrollbar{
  width: 7px;
}

body::-webkit-scrollbar-thumb{
  background: var(--bs-dark-green);
  border-radius: 3px;
}

.container-mt {
  --bs-x:1.5rem;
  padding-left: calc(var(--bs-x)*.5);
  padding-right: calc(var(--bs-x)*.5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;

}

@media (min-width: 768px){
  .container-mt {
    max-width: 720px;

    
  }
  .mt-mt{
    margin-top: 3rem !important;
  }

  .mt-h4{
    font-size: 2.5rem;
  }

  .mt-p{
    font-size: 1.5rem;
  }
  .mt-h2{
    font-size: 3rem;
  }

  .mt-h1{
    font-size: 4rem;
  }

  .avionv{
    padding-top: 51px;
  }
  .correra{
    margin-left: 4rem;
    height: 3rem;
  }

  .mt-h1-8{
   font-size: 8rem;
  }
  .mt-h3{
    font-size: 2rem;
  }

  .espacios{
    letter-spacing: 0.25rem;
    word-spacing: 0.25rem;
  }

  .imagen-avion{
    width: 15rem !important;
    height: 15rem !important;
  }

  .hombrecentro{
    margin-left: 1rem;
  }
  .flechacentro{
    margin-left: 2rem;
  }
  .mt-aseso{
    font-size: 6rem;
  }
  .alturafija{
    height: 265px;
  }

  

  .cont-img{
    width: 33%;
    height: 200px;
  }

  
}

@media(min-width:992px){

  .container-mt {
    max-width: 1000px;

    
  }

}

@media(min-width:1200px){

  .container-mt {
    max-width: 1200px;
  }

  .w-mt-50{
    width: 50% !important;
  }

  .mt-mt{
    margin-top: 4rem !important;

  }

  .no-wrap{
    flex-wrap: nowrap !important;
  }
  
  .w-mt-75{
    width: 65% !important;
  }

  .mt-justify-end{
    justify-content: end !important;
  }

  .cont-img{
    width: 33%;
    height: 376px;
  }


}